
import {
  Component,
  Vue
} from 'vue-property-decorator'
import DashboardService from '../services/dashboard-service'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
  @Component({
    name: 'BargeDashboard',
    components: {}
  })

export default class BargeDashboard extends Vue {
    private barge = {}

    created () {
      this.boot()
    }

    protected boot () {
      this.populateBargeData()
    }

    protected async populateBargeData () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      // const response = await DashboardService.getJobList()
    }
}

